<template>
  <div class="root">
    <el-image
      style="width: 400px; height: 240px"
      :src="sceneInfo.sitePicture"
      fit="cover"
    ></el-image>
    <div class="main">
      <h3>{{'恭喜您！团队预约成功'}}</h3>
      <p v-if="sceneInfo.siteName">
        场景名称：{{ sceneInfo.siteName }}
      </p>
      <p v-if="sceneInfo.siteAddress">场景地址：{{ sceneInfo.siteAddress }}</p>
      <p v-if="sceneInfo.siteContactPerson">场景联系人：{{ sceneInfo.siteContactPerson }}</p>
      <p v-if="sceneInfo.siteContactPhone">联系电话：{{ sceneInfo.siteContactPhone }}</p>
      <p v-if="sceneInfo.courseTime&&$store.state.courseInfo.courseStatus!=8">
        下节课的上课时间：{{ sceneInfo.courseTime }}，请提前到场
      </p>
      <p>可在个人中心——我的预约中查看详细预约信息
        <span @click="toUser" class="toUser">点击进入我的预约</span>
      </p>
    </div>
  </div>
</template>

<script>
import {get } from "@/utils/local.js"
export default {
  data() {
    return {
      sceneInfo:{},
    };
  },
  methods: {
    toUser(){
      if(this.$store.state.isLogin){
        this.$router.push("/user/order/myOrder")
      }else{
        this.$router.push("/login?redirect=/user/order/myOrder")
      }
    },
  },
  computed:{

  },
  created() {
    this.sceneInfo = get('scene')
  },
};
</script>

<style lang="less" scoped>
@title-color: #0079fe;
.root {
  display: flex;
  .el-image {
    border-radius: 6px;
  }
  .main {
    flex: 1;
    margin-left: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    h3 {
      text-align: center;
      font-weight: 700;
    }
    .toUser{
      margin-left:20px;
      color:@title-color;
      user-select: none;
      cursor:pointer;
    }
  }
}
</style>