<template>
  <div v-if="isShow"><EmptyBox class="empty">该系统暂未开放，敬请期待</EmptyBox></div>
  <div v-else class="root">
    <div :style="biStyle"></div>
    <div class="containar">
      <el-card class="top">
        <h3>
          {{ sceneInfo.siteName }}
        </h3>
        <div class="top-content">
          <div style="display: flex;margin-bottom:20px;padding-right:20px;">
              <el-image
                style="
                  width: 400px;
                  height: 200px;
                  vertical-align: top;
                  margin-right: 30px;
                "
                :src="sceneInfo.sitePicture"
                fit="cover"
              ></el-image>
              <div
                style="
                  flex: 1;
                  line-height: 2em;
                "
              >
                场景简介：<p style="text-indent:2em;">{{ sceneInfo.siteIntroduction }}</p>
              </div>
            </div>
            <p v-if="sceneInfo.siteHeat">
              <i class="el-icon-star-off"> </i> 场景热度：{{
                sceneInfo.siteHeat
              }}
            </p>
            <p>
              <i class="el-icon-connection"> </i> 人数限制：{{
                sceneInfo.siteLimitNumber >= 0 ? sceneInfo.siteLimitNumber : "暂无限制"
              }}
            </p>
            <p v-if="sceneInfo.siteAddress">
              <i class="el-icon-location-outline"> </i> 场景地址：{{
                sceneInfo.siteAddress
              }}
            </p>
        </div>
        <div class="form">
          <el-steps :active="active" finish-status="success" align-center>
            <el-step title="第一步"></el-step>
            <el-step title="第二步"></el-step>
            <el-step title="完成"></el-step>
          </el-steps>
          <First v-if="active === 0" @changeActive="changeActive" />
          <Second :openTime="sceneInfo.openTime" :closeTime="sceneInfo.closeTime" v-if="active === 1&&sceneInfo.openTime" @changeActive="changeActive" />
          <Thirdly v-if="active === 3" @changeActive="changeActive" />
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import moment from "moment"
import EmptyBox from "@/components/empty.vue";
import bc from "@/assets/img/sceneInfoBc.jpg";
import First from "./components/first.vue";
import Second from "./components/second";
import Thirdly from "./components/thirdly";
import { set, sessionGet, sessionSet, sessionClear } from "@/utils/local.js";
import { getSceneInfo_api } from "@/api/scene";
export default {
  components: {
    First,
    Second,
    Thirdly,
    EmptyBox,
  },
  data() {
    return {
      isShow:true,
      biStyle: {
        // 导航栏下面的通栏背景图
        height: "280px",
        backgroundImage: "url(" + bc + ")",
      },
      sceneInfo: "",
      active: 0, // 当前步骤
    };
  },
  methods: {
        // 获取数据
    async getSceneInfo() {
      let { data, code } = await getSceneInfo_api({
        siteId: this.$route.query.siteId,
      });
      if (code === 200) {
        // data.siteDetail = decodeURIComponent(data.siteDetail);
        data.openTime = moment(new Date(data.openTime)).format("HH:mm")
        data.closeTime = moment(new Date(new Date(data.closeTime).getTime()-10*60*1000)).format("HH:mm")
        this.sceneInfo = data;
        set('scene',this.sceneInfo)
      }
    },
    // 获取子组件传递的active参数
    changeActive(val) {
      sessionSet("sceneAddActive", val);
      this.active = val;
    },
  },
  created() {
    this.active = sessionGet("sceneAddActive") || 0;
    this.getSceneInfo()
  },
  beforeDestroy() {
    sessionClear("sceneAddActive");
  },
};
</script>

<style lang="less" scoped>
@btn-color: #f66866;
@title-color: #0079fe;
  .empty{
    padding:50px 0;
  }
.containar {
  margin-top: -200px;
  .top {
    margin: 100px 50px;
    h3 {
      font-size: 24px;
      font-weight: 700;
      color: @btn-color;
      margin: 20px;
    }
    .top-content {
      margin: 0 0 20px 20px;
      line-height: 2em;
      p {
        min-width:50%;
        display:inline-block;
      }
    }
  }
  .form {
    padding: 30px 100px;
    .el-steps {
      padding-bottom: 20px;
      margin-bottom: 30px;
      border-bottom: 1px solid #ccc;
    }
  }
}
</style>