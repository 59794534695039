<template>
  <div class="first">
    <p>一、预约声明</p>
    <p>声明 声明声明声明声明 声明 声明 声明 声明 声明 声明声明</p>
    <p>声明声明声明</p>
    <p>二、学生行为规范</p>
    <p>
      1.自觉接受管理部门安排的岗位和工作，服从调配，如有特殊情况应向管理部门提出。
    </p>
    <p>
      2.上岗实行签到制度，未签到、签退视服务无效。上岗和离岗时，到志愿者工作办公室签到及签退。
    </p>
    <p>3.准时到岗，不迟到，不早退，不缺勤。</p>
    <p>
      4.在工作岗位上不得无故脱岗，不扎堆闲聊，不得吃东西，不读书看报，不玩手机。如有特殊情况需离开，应取得工作人员同意。
    </p>
    <p>
      5.在岗期间需佩戴志愿者证，着统一发放的志愿者服，保持仪容仪表整洁，不着奇装异服，不穿拖鞋，不化浓妆，不戴夸张饰物。
    </p>
    <p>6.普通话用语标准，精神饱满。</p>
    <p>
      7.如遇观众咨询应热情主动，大方得体应答，不可态度傲慢、爱搭不理，避免与公众发生争吵、纠纷。
    </p>
    <p>8.未经允许不得从事任何与志愿服务无关的广告宣传活动。</p>
    <p>9.不得向服务对象收取任何财物报酬。</p>
    <p>
      10.志愿者在当日服务结束后，应及时持《四川科技馆志愿者服务证》到志愿者办公室盖章。本轮服务结束后，工作合格者到志愿者办公室领取交通补贴，并归还志愿者标识。
    </p>
    <p>
      11.表现消极、有损本馆和志愿者形象及日常考核不合格的志愿者，本馆将予以辞退，2年内不得再申请四川科技馆志愿者。
    </p>
    <p>
      请仔细阅读以上内容后谨慎填写报名表，便于准确建立志愿者资料库，我区每年将评选出“年度星级志愿者”。"
    </p>
    <div class="next">
      <el-checkbox v-model="checked">我已阅读并同意遵守相关条约</el-checkbox>
      <button @click="handleNext" class="btn">下一步</button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      checked: false, // 是否同意条约
    };
  },
  methods: {
    // 点击下一步
    handleNext() {
      if(!this.checked) this.$message.error('请阅读并同意相关条约');
      else this.$emit("changeActive",1)
    },
  },
};
</script>

<style lang="less" scoped>
@title-color: #0079fe;
.first {
  line-height: 1.6em;
  .next {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .btn {
      background-color: @title-color;
      height: 30px;
      width: 100px;
      margin-top: 10px;
    }
  }
}
</style>