<template>
  <div class="root">
    <h3>填写预约详情</h3>
    <div class="teacher">
      <el-form
        :model="ruleForm"
        :rules="rules"
        :inline="true"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="领队姓名" prop="leaderName">
          <el-input
            size="small"
            v-model="ruleForm.leaderName"
            placeholder="请输入领队姓名"
          ></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="leaderPhone">
          <el-input
            size="small"
            v-model="ruleForm.leaderPhone"
            placeholder="请输入领队手机号"
          ></el-input>
        </el-form-item>
        <el-form-item label="所属学校" prop="leaderSchool">
          <el-input
            size="small"
            v-model="ruleForm.leaderSchool"
            placeholder="请输入领队所属学校"
          ></el-input>
        </el-form-item>
        <el-form-item label="参观人数" prop="groupNumber">
          <el-input
            size="small"
            v-model="ruleForm.groupNumber"
            :placeholder="`${
              sceneInfo.siteLimitNumber > 0
                ? '最大参观人数为' + sceneInfo.siteLimitNumber
                : ''
            }`"
          ></el-input>
        </el-form-item>
        <el-form-item label="选择日期" prop="date">
          <el-date-picker
            size="small"
            v-model="ruleForm.date"
            type="date"
            placeholder="选择日期"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="到达时间" prop="time">
          <el-time-select
            size="small"
            placeholder="选择时间"
            v-model="ruleForm.time"
            :picker-options="{
              start: openTime,
              step: '00:10',
              end: closeTime,
            }"
          >
          </el-time-select>
        </el-form-item>
      </el-form>
      <el-upload
        class="upload-demo"
        multiple
        :limit="3"
        :file-list="ruleForm.uploadAttachment"
        :action="'/api/blade-resource/oss/endpoint/put-file'"
        :headers="headers"
        :on-exceed="handleExceed"
        :before-remove="beforeRemove"
        :on-success="handleAvatarSuccess"
        :before-upload="beforeUpload"
        :on-remove="handleRemove"
      >
        <el-button size="small" type="primary">点击上传附件</el-button>
      </el-upload>
    </div>
    <el-card class="students">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column label="姓名" width="120">
          <template slot-scope="scope">
            <span>{{ scope.row.studentName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="性别" width="80">
          <template slot-scope="scope">
            <span>{{
              scope.row.studentSex == 1 ? "男" : "女"
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="年级" width="190">
          <template slot-scope="scope">
            <span>{{
              studentGrade(scope.row.studentAge)
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="身份证号">
          <template slot-scope="scope">
            <span>{{
              scope.row.studentIdentityNumber
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="150">
          <template slot-scope="scope">
            <el-button size="mini" @click="handleEdit(scope.$index, scope.row)"
              >编辑</el-button
            >
            <el-button
              size="mini"
              type="danger"
              @click="handleDelete(scope.$index, scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-button
        @click="addStudent"
        style="margin: 20px 0 0 650px"
        size="small"
        type="primary"
        plain
        >添加学生</el-button
      >
    </el-card>
    <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
    <el-dialog title="学生信息" :visible.sync="dialogFormVisible">
      <el-form :rules="studentRules" :model="studentForm" ref="studentRuleForm">
        <el-form-item label="姓名" label-width="100px" prop="studentName">
          <el-input size="small" v-model="studentForm.studentName"></el-input>
        </el-form-item>
        <el-form-item
          label="身份证号"
          label-width="100px"
          prop="studentIdentityNumber"
        >
          <el-input
            size="small"
            v-model="studentForm.studentIdentityNumber"
          ></el-input>
        </el-form-item>
        <el-form-item label="性别" label-width="100px" prop="studentSex">
          <el-radio-group v-model="studentForm.studentSex">
            <el-radio label="1">男</el-radio>
            <el-radio label="2">女</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="年级" label-width="100px" prop="studentAge">
          <el-select
            size="small"
            v-model="studentForm.studentAge"
            placeholder="请选择年级"
          >
            <el-option
              v-for="(item, i) in studentGradeArr"
              :key="i"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="handleAddStudent(false)"
          >取 消</el-button
        >
        <el-button size="small" type="primary" @click="handleAddStudent(true)"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import moment from "moment";
import { get, sessionGet, sessionSet, sessionClear } from "@/utils/local.js";
import { ADDCOURSE, IDCODE_REG } from "@/utils/reg.js";
import { reservegroup_api } from "@/api/scene.js";
export default {
  props: ["closeTime", "openTime"],
  data() {
    var validateNum = (rule, value, callback) => {
      if (!ADDCOURSE.NUM_REG.test(value)) {
        callback(new Error("请输入正整数值"));
      } else if (
        Number(value) > this.sceneInfo.siteLimitNumber &&
        this.sceneInfo.siteLimitNumber >= 0
      ) {
        callback(new Error("参观人数不能大于限制人数"));
      } else {
        callback();
      }
    };
    // 手机号码验证
    var validateCourseContactPhone = (rule, value, callback) => {
      if (!value) return callback();
      if (!ADDCOURSE.telStr.test(value)) {
        callback(new Error("请输入规范的手机号"));
      } else {
        callback();
      }
    };
    // 身份证号码验证
    var validateIdCode = (rule, value, callback) => {
      if (!value) return callback();
      if (!IDCODE_REG.test(value)) {
        callback(new Error("请输入合法的身份证号"));
      } else {
        callback();
      }
    };
    return {
      pickerOptions: {
        // 只能选24小时之后的日期
        disabledDate(time) {
          return time.getTime() < Date.now() + 8.64e7;
        },
      },
      headers: {
        // 上传文件的请求头
        Authorization: "Basic cG9ydGFsOnBvcnRhbF9zZWNyZXQ=", // 网站标识‘portal’的base64编码后字符串
        "Tenant-Id": "501654",
        "Blade-Auth":
          sessionGet("tk").token_type + " " + sessionGet("tk").access_token,
      },
      sceneInfo: {}, // 场景详情
      ruleForm: {
        siteId: this.$route.query.siteId,
        leaderName: "",
        leaderPhone: "",
        leaderSchool: "",
        groupNumber: "",
        date: "",
        time: "",
        reserveDate: "",
        uploadAttachment: [], // 附件列表
      },
      rules: {
        leaderName: [
          { required: true, message: "请输入真实姓名", trigger: "blur" },
        ],
        leaderPhone: [
          { required: true, message: "请输入电话号码", trigger: "blur" },
          { validator: validateCourseContactPhone, trigger: "blur" },
        ],
        leaderSchool: [
          { required: true, message: "请输入所属学校", trigger: "blur" },
        ],
        groupNumber: [
          { required: true, message: "请输入参观人数", trigger: "blur" },
          { validator: validateNum, trigger: "blur" },
        ],
        date: [{ required: true, message: "请选择日期", trigger: "change" }],
        time: [
          { required: true, message: "请选择到达时间", trigger: "change" },
        ],
      },
      studentGradeArr: [
        { value: "1", label: "一年级" },
        { value: "2", label: "二年级" },
        { value: "3", label: "三年级" },
        { value: "4", label: "四年级" },
        { value: "5", label: "五年级" },
        { value: "6", label: "六年级" },
        { value: "7", label: "初一" },
        { value: "8", label: "初二" },
        { value: "9", label: "初三" },
      ],
      tableData: [],
      dialogFormVisible: false, // 显示模态框
      isEdit: false, // 判断模态框内表单是新增还是修改。默认新增
      editIndex: 0, // 修改学生在tableData中的索引
      studentForm: {
        // 学生信息
        studentName: "",
        studentSex: "",
        studentAge: "",
        studentIdentityNumber: "",
      },
      studentRules: {
        studentName: [
          { required: true, message: "请输入学生姓名", trigger: "blur" },
        ],
        studentSex: [
          { required: true, message: "请选择学生性别", trigger: "blur" },
        ],
        studentAge: [
          { required: true, message: "请选择学生年级", trigger: "blur" },
        ],
        studentIdentityNumber: [
          { required: true, message: "请输入学生身份证号", trigger: "blur" },
          { validator: validateIdCode, trigger: "blur" },
        ],
      },
    };
  },
  computed: {
    studentGrade() {
      return function (num) {
        let label;
        this.studentGradeArr.forEach((item) => {
          if (item.value == num) label = item.label;
        });
        return label;
      };
    },
  },
  methods: {
    // 提交
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.tableData.length > 0) {
            this.reservegroup();
          } else {
            this.$message.error("请添加学生信息");
          }
        } else {
          return false;
        }
      });
    },
    // reservegroup团队预约
    async reservegroup() {
      // 数据准备
      this.ruleForm.reserveDate = new Date(
        moment(this.ruleForm.date).format("yyyy-MM-DD") +
          " " +
          this.ruleForm.time +
          ":00"
      );
      this.ruleForm.studentList = JSON.parse(JSON.stringify(this.tableData));
      let { code, data, msg } = await reservegroup_api({
        reserveGroupJson: JSON.stringify(this.ruleForm),
      });
      if (code == 200) {
        this.$message({
          type: "success",
          message: msg,
        });
        this.$emit("changeActive", 3);
      } else {
        this.$message.error(msg);
      }
    },
    // 添加学生
    addStudent() {
      if (this.tableData.length == this.sceneInfo.siteLimitNumber)
        return this.$message.error("参观人数已经达到限制人数，不能继续添加！");
      this.dialogFormVisible = true;
    },
    // 确认添加学生
    handleAddStudent(bool) {
      if (!bool) {
        // 取消添加
        this.dialogFormVisible = false;
        this.studentForm = {
          // 学生信息
          studentName: "",
          studentSex: "",
          studentAge: "",
          studentIdentityNumber: "",
        };
      } else {
        // 确认添加
        this.$refs.studentRuleForm.validate((valid) => {
          if (valid) {
            if (!this.isEdit) {
              // 新增学生
              this.tableData.push({ ...this.studentForm });
            } else {
              // 修改学生信息
              this.tableData[this.editIndex] = { ...this.studentForm };
              this.tableData = [...this.tableData]; // 解决数组内对象属性变化，无法监听到的问题
            }

            sessionSet("studentData", this.tableData);
            this.studentForm = {
              // 学生信息
              studentName: "",
              studentSex: "",
              studentAge: "",
              studentIdentityNumber: "",
            };
            this.dialogFormVisible = false;
          } else {
            return false;
          }
        });
      }
    },
    // 上传附件数量达到上限
    handleExceed() {
      this.$message.error("最多上传3个附件");
    },
    // 文件上传前
    beforeUpload(file) {
      this.headers = {
        // 上传文件前获取请求头，防止长时间未操作，使用token更新前的token
        Authorization: "Basic cG9ydGFsOnBvcnRhbF9zZWNyZXQ=", // 网站标识‘portal’的base64编码后字符串
        "Tenant-Id": "501654",
        "Blade-Auth":
          sessionGet("tk").token_type + " " + sessionGet("tk").access_token,
      };
      return true;
    },
    // 移除上传的文件前确认框
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    // 移除文件后保存新数据
    handleRemove(file, fileList) {
      this.ruleForm.uploadAttachment = JSON.parse(JSON.stringify(fileList));
    },
    // 文件上传成功
    handleAvatarSuccess(res, file) {
      this.avatar = URL.createObjectURL(file.raw);
      this.ruleForm.uploadAttachment.push({
        name: res.data.originalName,
        url: res.data.link,
      });
    },
    // 编辑
    handleEdit(index, row) {
      this.isEdit = true;
      this.editIndex = index;
      this.studentForm = { ...row };
      this.dialogFormVisible = true;
    },
    // 删除
    handleDelete(index, row) {
      this.$confirm("此操作将删除该学生信息, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.tableData.splice(index, 1);
          this.$message({
            type: "success",
            message: "删除成功!",
          });
          sessionSet("studentData", this.tableData);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
  created() {
    this.tableData = sessionGet("studentData") || [];
    this.sceneInfo = get("scene");
  },
  beforeDestroy() {
    sessionClear("studentData");
  },
};
</script>

<style lang="less" scoped>
.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h3 {
    margin-bottom: 20px;
  }
  .teacher {
    width: 850px;
    .demo-ruleForm {
      .el-form-item {
        width: 48%;
        /deep/.el-input__inner {
          width: 300px;
        }
      }
    }
    .upload-demo {
      margin: 0 100px 30px;
    }
  }
  .students {
    margin-bottom: 30px;
    width: 800px;
  }
  .el-dialog {
    .el-form-item {
      display: inline-block;
      width: calc(50% - 40px);
    }
  }
}
</style>